.commonShelf {
    width: 100%;
    padding: 16px 0;
    border-radius: 12px;
    background-color: #f0f4f7;
}

.commonShelfHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0 16px 16px 16px;
    -webkit-tap-highlight-color: transparent;
}

.titleContainer {
    display: flex;
    gap: 8px;
    min-width: 0;
}

.titleLogo {
    width: 44px;
    height: 44px;
    flex: none;
}
.titleLogo_wide {
    width: unset;
    height: 28px;
}

.titles {
    min-width: 0;
}

.titleBox {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
    gap: 4px 6px;
}

.title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: break-word;
    font-family: "Ali_ver.18_Lighter-Rounded", Inter, sans-serif;
    font-size: 26px;
    line-height: 28px;
}
.titleWithDefaultFont {
    font-family: "Inter", "Open Sans", sans-serif;
}

.subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    overflow-wrap: break-word;
    font-family: "Inter", "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.13px;
}

.moreButton {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: auto;
    border-radius: 50px;
}

/* TODO: что это */
.link {
    display: flex;
    align-items: center;
    color: #547dff;
}

.cartButtonOutline {
    display: flex;
    margin-bottom: auto;
    padding: 2px;
    border-radius: 50px;
    cursor: pointer;
}

.cartButtonOutlineText {
    margin: 0 12px;
}

.cartButton {
    display: flex;
    height: 36px;
    padding: 0 12px;
    border-radius: 50px;
}

.cartIcon {
    position: relative;
    color: inherit;
    margin: auto 0 auto 6px;
}

.cartCounter {
    position: absolute;
    top: -7px;
    right: -7px;
    font-size: 9px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
    line-height: 12px;
    padding: 1px 2px;
    color: inherit;
    border-radius: 6px;
}

.moreSnippet {
    display: flex;
    height: 100%;
    margin: auto;
    padding: 0 5px;
}

.moreSnippetText:hover {
    color: #547dff !important;
}

.sliderWrapper {
    /* Оказался не нужным здесь, мб не нужен и в основных стилях */
}

.slider.slider {
    overflow-y: hidden;
    padding: 0 16px;
    /**
     * В сниппетах с COMMON_V3_PEAK модом рисуется иконка bookmark, которая на 3px вылезает за пределы сниппета,
     * и чтобы overflow: auto или scroll не обрезал вылезающую часть, padding'ом забираем себе эту область,
     * а margin'ом обратно освобождаем, что оставляет внешний вид нетронутым, влияя только на относительное позиционирование
     */
    padding-top: 3px;
    margin-top: -3px;
    gap: 6px;
    z-index: 2;
}
.sliderSmallGap.slider {
    gap: 4px;
}

.cartButtonWrapperMobile {
    display: flex;
    margin: 12px 12px 0 12px;
    flex: 0 0 100%;
}
