.product {
    position: relative;
    width: 170px;
    flex: none;
}
.redesignCommonMode.product {
    width: 160px;
}
.isMobile.product {
    width: 155px;
}
.commonV3Mode.product,
.imageWithTitleSnippet.product {
    width: 162px;
}

.link {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 6px;
    gap: var(--gap);
    border-radius: 4px;
    background-color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    --gap: 7px;
}
.redesignCommonMode .link {
    padding: 0 0 8px 0;
    border-radius: 6px;
    --gap: 4px;
}
.redesignCommonMode.isMobile .link {
    padding-bottom: 6px;
}
.commonV3Mode .link {
    padding: 6px 6px 10px 6px;
    border-radius: 10px;
    --gap: 4px;
}
.onlyImage .link {
    padding: 0;
    background: transparent;
}
.imageWithTitleSnippet .link {
    padding: 12px;
    --gap: 0;
}

.imageContainer {
    display: flex;
}
.onlyImage .imageContainer {
    background: rgba(211, 211, 211, 0.44) 50%;
}
.redesignCommonMode .imageContainer,
.commonV3Mode .imageContainer {
    background: transparent;
}
.commonV3Mode.onlyImage:not(.onlyImageSnippets) .imageContainer {
    position: relative;
    height: 100%;
}

.image {
    width: 158px;
    height: 158px;
    border-radius: 6px;
    object-fit: cover;
    pointer-events: none;
}
.isMobile .image {
    width: 143px;
    height: 143px;
}
.redesignCommonMode .image {
    width: 160px;
    height: 160px;
    border-radius: 6px 6px 0 0;
}
.redesignCommonMode.isMobile .image {
    width: 155px;
    height: 155px;
}
.onlyImage .image {
    width: 154px;
    height: 216px;
    border-radius: 6px;
    background: transparent;
    margin: auto;
    pointer-events: none;
}
.onlyImage.isMobile .image {
    width: 155px;
    height: 216px;
}
.commonV3Mode .image {
    width: 100%;
    height: 150px;
    border-radius: 6px;
}
.commonV3Mode.onlyImage .image {
    position: absolute;
    width: 100%;
    height: 100%;
}
.commonV3Mode.onlyImageSnippets .image {
    position: unset;
    height: 216px;
}
.imageWithTitleSnippet .image {
    height: 150px;
    border-radius: 0;
}

.badge {
    position: relative;
    width: max-content;
    /* 7px - сколько пикселей налезает на картинку */
    margin-top: calc(-1 * (var(--gap) + 7px));
    margin-bottom: calc(-1 * var(--gap));
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    padding: 2px 1px 2px 4px;
    background: #fe2722;
    color: #fff;
}
.commonV3Mode .badge {
    font-size: 11px;
    padding: 4px 1px 4px 5px;
    margin-top: calc(-1 * (var(--gap) + 8px));
}
.badge::after {
    content: "";
    position: absolute;
    top: 0;
    /* Выходим за пределы нашего блока на пиксель меньше, чтобы при зуме страницы */
    /* не было глитчей с полоской, из которой просачивается контент под бэджиком */
    right: -4px;
    width: 5px;
    height: 100%;
    background: inherit;
    /* иногда clip-path зачем-то срезает часть контента сверху, и чтобы исправить это, */
    /* натянул обрезку на 5% выше, чем видимый контент */
    clip-path: polygon(0 0, 100% -5%, 40% 100%, 0 100%);
}

.priceContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding: 3px 6px 6px 6px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    border-radius: 6px;
    background: #f0f4f7;
}
.redesignCommonMode .priceContainer {
    padding: 3px 10px 0 10px;
    background: transparent;
}
.commonV3Mode .priceContainer {
    padding: 4px 6px 0 6px;
    background: transparent;
}

.price {
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
}
.isMobile .price {
    font-size: 21px;
    line-height: 28px;
}
.redesignCommonMode .price,
.commonV3Mode .price {
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
}
.uzsPrice {
    font-size: 15px;
}

.discount {
    color: #000;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    letter-spacing: 0.065px;
}
.redesignCommonMode .discount,
.commonV3Mode .discount {
    font-size: 11px;
    line-height: 12px;
    height: 12px;
}
.uzsDiscount {
    font-size: 10px;
    word-break: break-all;
}

.cashbackText {
    height: 20px;
    font-size: 13px;
    line-height: 16px;
    padding-top: 4px;
    margin-left: 2px;
}
.redesignCommonMode .cashbackText,
.commonV3Mode .cashbackText {
    height: 16px;
    padding-top: 0;
    margin-left: 0;
}

.title {
    width: 100%;
    flex: none;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.065px;
    padding: 0 6px;
}
.redesignCommonMode .title {
    padding: 0 10px;
    height: 16px;
    max-height: 24px;
}
.redesignCommonMode .title,
.commonV3Mode .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
}
.commonV3Mode .tallerTitle {
    -webkit-line-clamp: 2;
    word-break: unset;
}
.titleForOnlyImage {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-size: 15px;
    line-height: 18px;
    margin-top: auto;
}

.deliveryText {
    font-size: 13px;
    line-height: 16px;
    padding: 0 6px;
}
.redesignCommonMode .deliveryText {
    padding: 0 10px;
}

.stocksText {
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;
}

.ordersCountText {
    font-size: 13px;
    line-height: 16px;
    padding: 0 6px;
}

.bookmarkIcon {
    position: absolute;
    top: -3px;
    left: 12px;
}
