.skeleton {
    height: 341px;
    width: 100%;
    background-color: white;
    padding: 14px;
}

.skeletonHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.skeletonTitles {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}

.products {
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    width: 100%;
}

.skeletonSnippet {
    height: 262px;
    width: 170px;
}

@media (--snow-tablet) {
    .skeleton {
        height: 347px;
    }

    .skeletonHeader {
        align-items: center;
    }

    .skeletonTitles {
        flex-direction: row;
        gap: 12px;
    }
}
